import TimePicker from './time_picker';

class ShowingsForm {

  bindEvents() {
    document.querySelectorAll('.js-showing-form-toggle-panel').forEach(link => {
      link.addEventListener('click', () => {
        $('.showing-panel').toggle();
        document.dispatchEvent(new CustomEvent('sm-modal:update-height'));
      });
    });

    $('#save_as_lead').click(function(e){
      e.preventDefault();
      $('<input />').attr('type','hidden')
        .attr('name','is_lead')
        .attr('value','true')
        .appendTo('form#showing_form');
      $('#showing_showtime').val('');
      $('#showing_lockbox_showtime').val('');
      Rails.fire($('form#showing_form')[0], 'submit');
      $('form#showing_form').find('input:last').remove();
      return false;
    });

    $('#showing_submit').click(function() {
      if ($(this).attr('disabled')) return;

      if($('#showing_on_lockbox').val() == 'true') {
        $('.question.only_for_lockbox :input').attr('disabled', false);
      }

      Rails.fire($(this).parents('form#showing_form')[0], 'submit');
    });

    $(document).on('ajax:send ajaxSend', function() {
      $('#showing_submit').attr('disabled', 'disabled');
    });
    $(document).on('ajax:complete ajaxComplete', function() {
      $('#showing_submit').removeAttr('disabled');
    });

    $('#showing_listing_id').change(function() {
      const val = $(this).val();
      if (val !== '') {
        $('#showing_form').trigger('ajax:beforeSend');

        var params = $.param({
          'showing[listing_id]': $(this).val(),
          'id': $('#showing_id').val(),
          team_member_id: $('#showing_team_member_id').val(),
          date: $('#custom_showtime_date').val(),
          time: $('#custom_showtime_time').val()
        });
  
        $.ajax({
          type: 'POST',
          url: '/on_change_listing?' + params,
          success: function(){
            $('#showing_form').trigger('ajax:complete');
            $('#showing_listing_id').trigger('listing_changed');
          },
          error: function(){
            $('#showing_form').trigger('ajax:complete');
          }
        });
      }
    });

    $('#show_all_listings_link').click(function() {
      if ($(this).hasClass('active')) {
        return false;
      }
      $('#showing_form').trigger('ajax:beforeSend');

      var params = $.param({
        'showing[listing_id]': $('#showing_listing_id').val(),
        'id': $('#showing_id').val()
      });

      $('#showtime_type').val('custom').trigger('change');
      $.ajax({
        type: 'POST',
        url: '/all_listings?' + params,
        success: function(){
          $('#showing_form').trigger('ajax:complete');
          $('#showing_listing_id').trigger('listing_changed');
          $('#show_all_listings_link').addClass('active');
        },
        error: function(){
          $('#showing_form').trigger('ajax:complete');
        }
      });
      return false;
    });
  }

  switchShowtimeTypeForSurge() {
    if ($('#showing_team_member_id').find(':selected').text().indexOf('(Surge Agent)') >= 0) {
      $("#showtime_type option[value='available']").remove(); // eslint-disable-line quotes
      $('#showtime_type').val('custom').trigger('change');
      return true;
    } else {
      return false;
    }
  }

  switchShowingTeamMember() {
    const self = this;
    if (!this.switchShowtimeTypeForSurge()) {
      // Get agent's available showtimes
      if ($('#showing_form')[0]) {
        $('#showing_form')[0].dispatchEvent(new CustomEvent('ajax:beforeSend'));
      }

      var params = $.param({
        showing_id: this.getParam('showingId'),
        'showing[listing_id]': $('#showing_listing_id').val(),
        team_member_id: $('#showing_team_member_id').val(),
        skip_on_lockbox: 'true',
        on_lockbox: $('#showing_on_lockbox').val(),
        custom_duration: $('#custom_showtime_duration').val(),
        custom_showtime: $('#showing_showtime').val()
      });

      $.get('/show_times?' + params, function(data){
        $('.time_picker').html(data);
        new TimePicker().init();
        if ($('#showing_form')[0]) {
          $('#showing_form')[0].dispatchEvent(new CustomEvent('ajax:complete'));
        }

        const showtime = $('#shown-showtime').val();
        const onLockboxParam = self.getParam('showingOnLockbox');
        const onLockbox = onLockboxParam !== '' ? JSON.parse(onLockboxParam) : false;

        if (!onLockbox) {
          const picker = document.querySelector('#available_showtimes');
          const setShowtime = !TimepickerBuilder.isSuitableTime(picker, showtime);
          if (picker) {
            picker.dispatchEvent(new CustomEvent('showtime-picker:setShowtime', {detail: {showtime}}));
          }
          
          if (setShowtime) {
            $('#shown-showtime, #showing_showtime').val('');
          }
        }
      });
    }
  }

  getParam(param) {
    const popup = document.querySelector('.js-showing-popup-wrapper');
    return popup.dataset[param];
  }

  initLeadDistributionCalendar(loadShowtimes = false) {
    $('#showing_team_member_id').on('change', () => this.switchShowingTeamMember());
    if (loadShowtimes) {
      this.switchShowingTeamMember();
    } else {
      this.switchShowtimeTypeForSurge();
    }

    if (JSON.parse(this.getParam('canEditCustomShowtime'))) {
      var switch_custom_showtime = function() {
        const showtime_type_options = $('#showtime_type option');
        const showtime_types = [...showtime_type_options].map(el => el.value);
        if($('#showing_team_member_id').val() !== '') {
          // Team member
          if ($('#show_all_listings_link').hasClass('active') && showtime_types.find((el) => el === 'custom')) {
            $('#showtime_type').val('custom').trigger('change');
          }
        } else {
          // Auto-assign
          $('#custom_showtime_date, #custom_showtime_time').val('');
          if ($('#show_all_listings_link').hasClass('active') && showtime_types.find((el) => el === 'custom')) {
            $('#showtime_type').val('custom').trigger('change');
          } else if (showtime_types.find((el) => el === 'available')) {
            $('#showtime_type').val('available').trigger('change');
          }
        }
      };

      $('#showing_listing_id').bind('listing_changed', switch_custom_showtime);
      $('#showing_team_member_id').on('change', function() {
        switch_custom_showtime();
      });

      $('#showing_form').on('ajax:complete', function() {
        switch_custom_showtime();
      });

      switch_custom_showtime();
    }
  }

  initTwilio() {
    function call(phoneNumber) {
      Twilio.Device.connect({ 'PhoneNumber': phoneNumber });
    }

    function hangup() {
      Twilio.Device.disconnectAll();
    }

    if (typeof(Twilio) === 'function') {
      Twilio.Device.setup(this.getParam('capabilityToken'));

      Twilio.Device.on('ready', function () {
        console.log('Ready');
      });

      Twilio.Device.on('connect', function () {
        console.log('Successfully established call');
      });

      Twilio.Device.on('error', function (error) {
        console.log('Error: ' + error.message);
      });

      Twilio.Device.on('disconnect', function () {
        $('#hangup_button').hide();
        $('#dial_out_button').show();
      });

      Twilio.Device.on('incoming', function (conn) {
        console.log('Incoming connection from ' + conn.parameters.From);
        conn.accept();
      });

      $('#dial_out_button').click(function(e) {
        e.preventDefault();

        var phoneNumber = $('#showing_phone').val();
        if (phoneNumber != '') {
          call(phoneNumber);
          $('#dial_out_button').hide();
          $('#hangup_button').show();
        }
      });

      $('#hangup_button').click(function(e){
        e.preventDefault();
        hangup();

        $('#hangup_button').hide();
        $('#dial_out_button').show();
      });
    }
  }

  initShowConfirmation() {
    document.getElementById('request_document_link').addEventListener('click', () => {
      if ($('#showing_phone').val() =='') {
        alert('Please provide a phone number');
        return;
      }

      const html = `<div class="sm-modal-content">
        <div id="confirm_message">
          A text message requesting documents </br> will be sent to ${$('#showing_phone').val()}
        </div>
        <div class="buttons-list right-aligned">
          <a class="btn btn-primary js-send-message">Send message</a>
          <a class="btn btn-default js-modal-close-trigger">Cancel</a>
        </div></div>`;
      const path = this.getParam('requestDocumentPath');
      document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {
        content: html, 
        modalId: 'confirm-message-modal',
        hideCloseButton: true,
        onComplete: () => {
          document.querySelector('.js-send-message').addEventListener('click', () => {
            $.post(path, {phone: $('#showing_phone').val()})
              .done(function() {
                $('#document_requested_label').show();
                document.dispatchEvent(new CustomEvent('sm-modal:close', {detail: {modalId: 'confirm-message-modal'}}));
              });
          });
        }
      }}));
    });
  }

  init(loadShowtimes = false) {
    new TimePicker().init();
    prospectVerificationMethod.init();
    window.submitted = false;

    if (JSON.parse(this.getParam('showTwilio'))) {
      this.initTwilio();
    }

    if (JSON.parse(this.getParam('leadDistributionCalendar'))) {
      this.initLeadDistributionCalendar(loadShowtimes);
    }

    if (JSON.parse(this.getParam('showConfirmation'))) {
      this.initShowConfirmation();
    }

    $('#dialog-confirm').hide();

    this.bindEvents();
  }
}

function initShowingsForm() {
  if (document.querySelector('.js-showing-popup-wrapper')) {
    const sf = new ShowingsForm();
    sf.init(false);
    document.addEventListener('showings-popup:reloaded', () => {sf.init(true);});
    document.addEventListener('listing:changed', () => {
      new TimePicker().init();
    });
  }
}

// For general ShowMojo application
document.addEventListener('sm-modal:opened', (e) => {
  if (e.detail.modalContainer && e.detail.modalContainer.querySelector('.js-showing-popup-wrapper')) {
    initShowingsForm();
  }
});

// For old mobile /m application
document.addEventListener('DOMContentLoaded', () => {
  initShowingsForm();
});
