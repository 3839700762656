import Rails from '@rails/ujs';
Rails.start();
window.Rails = Rails;

window.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
import '../src/application/dashboard/dashboard';
import '../src/application/dashboard/expand_showings_controls';
import '../src/application/dashboard/showings_calendar/showings_calendar';
import '../src/application/dashboard/showings_calendar/styles.scss';
import '../src/application/dashboard/two_way_messaging/two_way_messaging';
import '../src/application/dashboard/tooltips';
import '../src/application/application.css';
import '../src/application/listing_default_base';
import '../src/application/listings/bulk_update';
import '../src/application/listings/self_showings';
import '../src/application/listings/override_location';
import '../src/application/listings/showing_acceptance';
import '../src/application/listings/showing_notification';
import '../src/application/listings/video_form';
import '../src/application/listings/internal_notes_form';
import '../src/application/listings/details_form';
import '../src/application/listings/options_form';
import '../src/application/listings/self_guided_tour_form';
import '../src/application/listings/edit';
import '../src/application/listings/options';
import '../src/application/listings/image_uploader';
import '../src/application/listings/required_listing_updates/general';
import '../src/settings/rental_application/signature';
import '../src/application/showings/showings_form';
import '../src/application/showings/time_picker';

//Settings
import '../src/settings/scheduling_requirements.js';
import '../src/settings/schedule/no_availabilities';
import '../src/settings/billing/cancel_account';
import '../src/settings/account_general';
import '../src/settings/communications/dynamic_prospect_notifications_form';
import '../src/settings/digital_access/resident_access';
import '../src/settings/order_device/order_device';
import '../src/settings/listing_default';
import '../src/settings/gallery_branding/company_logo';
import '../src/settings/gallery_branding/special_announcement_form';
import '../src/settings/questionnaire/questionnaire_constructor';
import '../src/settings/questionnaire/questions/bedrooms';
import '../src/settings/questionnaire/questions/card';
import '../src/settings/questionnaire/questions/custom_date';
import '../src/settings/questionnaire/questions/custom';
import '../src/settings/questionnaire/questions/document_verification';
import '../src/settings/questionnaire/questions/dropdown';
import '../src/settings/questionnaire/questions/income';
import '../src/settings/questionnaire/questions/move_date';
import '../src/settings/questionnaire/questions/pets';
import '../src/settings/questionnaire/questions/roommates';
import '../src/settings/questionnaire/questions/tenant_address';
import '../src/settings/team/multi_user_listings_and_showings.js';
import '../src/settings/team/multi_user_permissions.js';
import '../src/settings/team/multi_user_other_settings.js';
import '../src/settings/listing_sites/paid_addresses';
import '../src/settings/watermarking';
import '../src/settings/short_term_rentals/guest_messaging';
import '../src/settings/import_providers';

//Prospects
import '../src/prospects/showings_form';
import '../src/prospects/listing_carousel';
import '../src/prospects/meeting_notification';

//Properties
import '../src/properties/edit';
import '../src/properties/floor_plan';

//Calendar
import '../src/application/calendar/options';

// For specs we need to count all active requests
(function() {
  var oldOpen = XMLHttpRequest.prototype.open;
  window.openHTTPs = 0;
  XMLHttpRequest.prototype.open = function(method, url, async = true, user, pass) {
    window.openHTTPs++;
    this.addEventListener('readystatechange', function() {
      if(this.readyState == 4) {
        window.openHTTPs--;
      }
    }, false);
    oldOpen.call(this, method, url, async, user, pass);
  };
})();
