import Mojolock from './devices/mojolock';
import MojolockG3 from './devices/mojolock_g3';
import MojolockG4Black from './devices/mojolock_g4_black';
import MojolockG4Silver from './devices/mojolock_g4_silver';
import MojolockPromo from './devices/mojolock_promo';
import Mojobox from './devices/mojobox';
import Codebox from './devices/codebox';
import CodeboxJunior from './devices/codebox_junior';
import Vault from './devices/vault';

class DeviceConstructor {
  // Returns Device class that should implement:
  //
  // constructor - calculate #price for given account
  // @param account user account with all required settings
  //
  // getPrice - returns number price in dollars
  // printFormattedPrice - print price with currency for Order Device on Account setup page in the description section
  // toggleCodeboxNote - shows or hides fields required only for Codebox devices
  // toggleAddressBehavior - shows only required address fields for shipping and hides excess fields
  constructor(type, types, account) {
    if (type === types.TYPE_MOJOLOCK) {
      return new Mojolock(account);
    } else if (type === types.TYPE_MOJOLOCK_G3) {
      return new MojolockG3(account);
    } else if (type === types.TYPE_MOJOLOCK_G4_BLACK) {
      return new MojolockG4Black(account);
    } else if (type === types.TYPE_MOJOLOCK_G4_SILVER) {
      return new MojolockG4Silver(account);
    } else if (type === types.TYPE_MOJOLOCK_PROMO) {
      return new MojolockPromo(account);
    } else if (type === types.TYPE_MOJOBOX) {
      return new Mojobox(account);
    } else if (type === types.TYPE_REGULAR) {
      return new Codebox(account);
    }else if (type === types.TYPE_VAULT) {
      return new Vault(account);
    } else if (type === types.TYPE_JUNIOR) {
      return new CodeboxJunior(account);
    }
  }
}

export default DeviceConstructor;
