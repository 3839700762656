import DashboardUtils from '../utils/dashboard_utils';
import {postMessageToSameOrigin} from '../utils/iframe_helper';

class ShowingsForm {
  #showingFormControlsInited;
  #wrapper;

  // eslint-disable-next-line  no-unused-vars
  isValidPhone(val) {
    if (!val) { val = $('#showing_phone').val(); }
    val = val.replace(/\D/g, '');
    if (!val.startsWith('1')) { val = '1' + val; }

    return (val.length == 11);
  }

  prepareShowingFormControls() {
    if (this.#showingFormControlsInited) { return; }
    this.#showingFormControlsInited = true;

    if (this.#wrapper.dataset.showtime) {
      const picker = document.querySelector('#available_showtimes');
      if (picker) {
        picker.dispatchEvent(new CustomEvent('showtime-picker:setShowtime', {detail: {showtime: this.#wrapper.dataset.showtime}}));
      } 
    }

    const wrapper = this.#wrapper;
    $('form').each(function() {
      $(this).submit(function() {
        if ($('input[type="submit"]').attr('disabled')) {
          return false;
        } else {
          $('input[type="submit"]').attr('disabled', 'disabled');
          if (JSON.parse(wrapper.dataset.lockbox)) {
            set_lockbox_when_showtime_not_selected();
          }
        }
      });
    });

    function set_lockbox_when_showtime_not_selected() {
      var lockbox_mode = ($('#showing_on_lockbox').val() == 'true');
      var showtime_selected = ($('ul.hours.active .button.active').length != 0) || ($('ul.showtime-list li.showtime.active').length != 0);
      if (!lockbox_mode && !showtime_selected) {
        $('#showing_on_lockbox').val('true');
      }
    }

    function get_upcoming(){
      if ($('#showing_email').val() == $('div#upcoming_data').find('input#upcoming_email').attr('value') || $('#showing_email').val() == '') { return false; }

      var url = window.location.toString().replace(/\/$/, '')
        .replace(/\?.*/, '') + '/upcoming/' + $('#showing_email').val() + '/p/' + $('#showing_phone').val();

        if (wrapper.dataset.listingUid) {
        url += `?listing_uid=${wrapper.dataset.listingUid}`;
      }
      $.get(url, function(data) {
        $('div#other_places_container').html(data);
      });
    }

    if (this.#wrapper.dataset.embeddedPopup == '') {
      $('#showing_email, #showing_phone').change(get_upcoming);
      if($('#showing_email').val() !== '' || $('#showing_phone').val() !== '') {
        get_upcoming();
      }
    }

    $('#new_showing').submit(function(e) {
      var form = this;
      e.preventDefault();
      $('.loader').show();

      setTimeout(function() {
        form.submit();
      }, 100);
    });
  }

  bindEvents() {
    const wrapper = this.#wrapper;
    let _this = this;
    let phoneEmailChangeHandler = {
      debounce: function(type, mil) {
        var timer;
        var self = this;

        return function() {
          clearTimeout(timer);
          timer = setTimeout(function() {
            if (type == 'card') {
              self.cardVerificationCheck();
            } else if (type == 'showtimes') {
              self.refreshShowtimes();
            }
          }, mil);
        };
      },

      cardVerificationCheck: function() {
        // Only make a trip to server if both inputs are present
        if ($('#showing_phone').val() != '' && $('#showing_email').val() != '') {
          var email = $('#showing_email').val(),
            phone = $('#showing_phone').val(),
            account_id = wrapper.dataset.accountId;

          $.ajax({
            url: '/card_verification_check',
            type: 'GET',
            data: { account_id: account_id, email: email, phone: phone }
          });
        }
      },

      refreshShowtimes: function() {
        if (JSON.parse(wrapper.dataset.allowDoubleBooking)) { return; }

        var phone = $('#showing_phone').val();
        var email = $('#showing_email').val();
        var previousPhone = $('#showing_phone').data('previous-value');
        var previousEmail = $('#showing_email').data('previous-value');
        var listingUid = wrapper.dataset.listingUid;
        var showingUid = wrapper.dataset.showingUid;
        var selectedShowtime = $('#showtime').val();
        if (phone == '' || email == '') { return; }
        if (phone == previousPhone && email == previousEmail ) { return; }
        if (!_this.isValidPhone(phone) || !DashboardUtils.isValidEmail(email)) { return; }

        $('.js-showtimes-loader').show();
        $('.js-showtimes-table').hide();
        $('#showing_phone').data('previous-value', phone);
        $('#showing_email').data('previous-value', email);
        $.ajax({
          url: '/refresh_showtimes',
          type: 'GET',
          data: {
            id: listingUid,
            showing: {
              uid: showingUid,
              email: email,
              phone: phone,
              showtime: selectedShowtime
            },
            force: true
          },
          complete: function() {
            $('.js-showtimes-table').show();
            $('.js-showtimes-loader').hide();
          }
        });
      }
    };

    if (!wrapper.classList.contains('js-create-prospects-showings')) {
      // Track and debounce keyup events to reduce the load on the server/DB
      $('#showing_phone, #showing_email').on('keyup', phoneEmailChangeHandler.debounce('card', 1000));
      $('#showing_phone, #showing_email').on('keyup', phoneEmailChangeHandler.debounce('showtimes', 2000));
    }
  }

  loadShowtimes() {
    const listingUid = this.#wrapper.dataset.listingUid;
    const showingUid = this.#wrapper.dataset.showingUid;
    const embeddedPopup = this.#wrapper.dataset.embeddedPopup;
    const vParam = this.#wrapper.dataset.vParam;

    let data = {
      id: listingUid,
      showing: {
        uid: showingUid,
        showtime: $('#showtime').val()
      },
      showtime: $('#shown-showtime').val()
    };

    if (embeddedPopup) {
      data['embedded_popup'] = embeddedPopup;
    }

    if (vParam) {
      data['v'] = vParam;
    }

    if (listingUid) {
      data['listing_uid'] = listingUid;
    }

    $.ajax({
      url: '/load_showtimes',
      type: 'GET',
      data: data
    });
  }

  init(wrapper) {
    this.#wrapper = wrapper;
    this.#showingFormControlsInited = false;
    this.bindEvents();

    function finishOnboarding() {
      var showingUid = wrapper.dataset.showingUid;
      postMessageToSameOrigin({ action: 'ONBOARDING_SHOWING_SCHEDULED', showing_uid: showingUid });
    }

    if (wrapper.classList.contains('js-create-prospects-showings')) {
      // Give a user 5 sec to read a message before redirecting
      setTimeout(finishOnboarding, this.#wrapper.dataset.redirectionTimeout);


    } else {
      if (!String.prototype.endsWith) {
        String.prototype.endsWith = function(suffix) {
          return this.indexOf(suffix, this.length - suffix.length) !== -1;
        };
      }

      if ($('#showingModal').hasClass('in')) {
        // If modal window is opened already, we simply init form controls
        this.prepareShowingFormControls();
      } else {
        // Otherwise, we add form controls initialization as a callback
        $('#showingModal').on('shown.bs.modal', () => this.prepareShowingFormControls());
      }

      if (window.self !== window.top ) {
        this.prepareShowingFormControls();
      }

      postMessageToSameOrigin({ action: 'ONBOARDING_HEADER_LOADED' });
    }

    this.loadShowtimes(wrapper);
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const wrapper = document.querySelector('.js-prospects-showings');
  if (wrapper) {
    new ShowingsForm().init(wrapper);
  }
});
