import LayoutUtils from '../../utils/layout_utils';
import FetchUtils from '../../utils/fetch_utils';
import CalendarOptionsUtils from './calendar_options_utils';

class CalendarOptions {
  #submitted = false;
  #valid = false;

  bindNumberFieldsEvents() {
    const duration =  document.querySelector('#calendar_duration');
    if (duration) {
      duration.addEventListener('change', (e) => CalendarOptionsUtils.stackValueChangeHandler(e.target, 5));
    }
    
    document.querySelector('#calendar_lead_hours').addEventListener('change', (e) => CalendarOptionsUtils.stackValueChangeHandler(e.target));
    const leadHoursToPiggyback = document.querySelector('#calendar_lead_hours_to_piggyback');
    if (leadHoursToPiggyback) {
      leadHoursToPiggyback.addEventListener('change', CalendarOptionsUtils.update_lead_hours_to_piggyback);
    }
    
    const calendarStacked = document.querySelector('#calendar_stacked_showing_period_minutes');
    if (calendarStacked) {
      calendarStacked.addEventListener('change', (e) => CalendarOptionsUtils.stackValueChangeHandler(e.target));
    }
    
    const leadHoursToStack = document.querySelector('#calendar_lead_hours_to_stack');
    if(leadHoursToStack) {
      leadHoursToStack.addEventListener('change', (e) => CalendarOptionsUtils.stackValueChangeHandler(e.target));
    }
    
    const maxConcurrent = document.querySelector('#calendar_max_concurrent');
    if (maxConcurrent) {
      maxConcurrent.addEventListener('change', CalendarOptionsUtils.update_lead_hours_to_piggyback);
    }
    
    document.querySelector('#calendar_cal_type').addEventListener('change', CalendarOptionsUtils.refreshCalendarTypeControls);
  }

  changeCalendarLocation(lock_type, text) {
    LayoutUtils.show(document.querySelector('#calendar_lock_type'));

    if (text) {
      CalendarOptionsUtils.changeLockLimitsOptionsText(text);
    }
  
    CalendarOptionsUtils.hideAllLockType();
    LayoutUtils.hide(document.querySelector('.lock_type_none'));

    const selector = `.lock_type_listing_${this.getLockTypeShortcut(lock_type)}`;
    LayoutUtils.show(document.querySelector(selector));
  }

  calendarLocationSelectHandler(e) {
    const value = e.target.value;
    const lock_type = document.querySelector('#calendar_lock_type').value;
    const location_lock = document.querySelector('#calendar_location_lock').value;
    const autonomous = document.querySelector('#autonomous_calendar_location_lock');
    if (autonomous) {
      const autonomous_location_lock = document.querySelector('#autonomous_calendar_location_lock').value;
      if (location_lock != autonomous_location_lock && [CalendarOptionsUtils.lockTypes.ONE_LISTING, CalendarOptionsUtils.lockTypes.ONE_PROPERTY].includes(location_lock)) {
        autonomous.value = location_lock;
      }
    }

    if(value == CalendarOptionsUtils.lockTypes.NONE) {
      LayoutUtils.hide(document.querySelectorAll('#calendar_lock_type, .lock_type-show'));

      CalendarOptionsUtils.hideAllLockType();
      LayoutUtils.show(document.querySelector('.lock_type_none'));
    }
    else if (value == CalendarOptionsUtils.lockTypes.ONE_LISTING) {
      this.changeCalendarLocation(lock_type, 'listing');
    }
    else if (value == CalendarOptionsUtils.lockTypes.ONE_PROPERTY) {
      this.changeCalendarLocation(lock_type, 'property');
    }
    else if(value == CalendarOptionsUtils.lockTypes.ONE_LOCATION) {
      this.changeCalendarLocation(lock_type);
    }

    CalendarOptionsUtils.show_hide_showing_limit();
    CalendarOptionsUtils.show_hide_lead_hours_to_stack();
  }

  getLockTypeShortcut(lock_type) {
    return lock_type == 'each_window' ? 'w' : 'st';
  }

  calendarLockTypeChangeHandler(e) {
    let lock_type = e.target.value;
    let value = document.querySelector('#calendar_location_lock').value;

    CalendarOptionsUtils.hideAllLockType();
    LayoutUtils.hide(document.querySelector('.lock_type_none'));

    if ( lock_type == 'each_showtime' || lock_type == 'each_window') {
      const selector = `.lock_type_${value.replace('ONE_', '').toLowerCase()}_${this.getLockTypeShortcut(lock_type)}`;
      LayoutUtils.show(document.querySelector(selector));
    }

    CalendarOptionsUtils.show_hide_showing_limit();
    CalendarOptionsUtils.show_hide_lead_hours_to_stack();
  }

  bindEvents(form) {
    this.bindNumberFieldsEvents();
  
    document.querySelector('#calendar_location_lock').addEventListener('change', this.calendarLocationSelectHandler.bind(this));

    const submit = document.querySelector('#frmOPT-submit');
    if (submit) {
      submit.addEventListener('click', () => {
        if (this.#submitted) { return false; }
    
        this.#submitted = true;
  
        if (document.querySelector('#calendar_duration')) {
          const duration =  document.querySelector('#calendar_duration').value;
          fetch(`${form.dataset.showDurationPath}?duration=${duration}`).then(FetchUtils.checkResponseStatus)
            .then((resp) => {
              return resp.json();
            }).then(data => {
              const msg = 'You have showing windows that are too short for the showing duration you have selected. Would you like ShowMojo to automatically increase the duration of those showing windows?';
    
              if (!data['shorter_show_windows'] || confirm(msg)) {
                this.#valid = true;
                Rails.fire(document.querySelector('#frmOPT'), 'submit');
              } else {
                this.#submitted = false;
              }
            }).catch((err) => {
              FetchUtils.handleResponseError(err);
            });
        }
      });
    }

    const stackingOn = document.querySelector('#calendar_stacking_on');
    if (stackingOn) {
      stackingOn.addEventListener('change', function(e) {
        document.querySelector('#stacking_status').textContent = e.target.checked ? 'enabled' : 'disabled';
      });
    }

    const autonomousLock = document.querySelector('#autonomous_calendar_location_lock');
    if (autonomousLock){
      autonomousLock.addEventListener('change', function(e){
        const value = e.target.value;
  
        const location_lock = document.querySelector('#calendar_location_lock').value;
        const autonomous_location_lock = document.querySelector('#autonomous_calendar_location_lock').value;
        const calTypeAutonomous = document.querySelector('#calendar_cal_type').value == CalendarOptionsUtils.CALENDAR_AUTONOMOUS_SCHEDULING;
  
        if (calTypeAutonomous && autonomous_location_lock != location_lock) {
          document.querySelector('#calendar_location_lock').value = autonomous_location_lock;
        }
  
        if (value == CalendarOptionsUtils.lockTypes.ONE_LISTING) {
          CalendarOptionsUtils.changeLockLimitsOptionsText('listing');
        }
        else if (value == CalendarOptionsUtils.lockTypes.ONE_PROPERTY) {
          CalendarOptionsUtils.changeLockLimitsOptionsText('property');
        }
  
        CalendarOptionsUtils.show_hide_showing_limit();
        CalendarOptionsUtils.show_hide_lead_hours_to_stack();
      });
    }

    document.querySelector('#calendar_lock_type').addEventListener('change', this.calendarLockTypeChangeHandler.bind(this));

    const piggybackingOn = document.querySelector('#calendar_piggybacking_on');
    if (piggybackingOn) {
      piggybackingOn.addEventListener('change', function(e) {
        const location_lock = document.querySelector('#calendar_location_lock').value;
        const lock_type_windows = document.querySelector('#calendar_lock_type').value == CalendarOptionsUtils.SW_LOCK_TYPE_WINDOWS;
        const calTypeAutonomous = document.querySelector('#calendar_cal_type').value == CalendarOptionsUtils.CALENDAR_AUTONOMOUS_SCHEDULING;
        
        const shown = (location_lock && lock_type_windows || calTypeAutonomous);
        CalendarOptionsUtils.toggleElement('.showing_stacking', shown);
  
        document.querySelector('#piggybacking_status').textContent = e.target.checked ? 'enabled' : 'disabled';
      });
    }

    document.querySelector('#frmOPT').addEventListener('submit', () => { return this.#valid; });
  }

  init(wrap) {
    this.bindEvents(wrap);
    if (JSON.parse(wrap.dataset.onlyBasic)) {
      LayoutUtils.hide(document.querySelectorAll('.hide-for-basic-calendar'));
    }
    CalendarOptionsUtils.refreshCalendarTypeControls();
    CalendarOptionsUtils.update_lead_hours_to_piggyback();

    document.querySelector('#calendar_location_lock').dispatchEvent(new Event('change')); 
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const form = document.querySelector('.js-calendar-options-form');
  if (form) {
    new CalendarOptions().init(form);

    document.addEventListener('html-updated', () => {
      new CalendarOptions().init(document.querySelector('.js-calendar-options-form'));
    });
  }
});