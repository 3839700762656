import DeviceUtils from '../device_utils';

class Mojolock {
  #price;

  constructor(account) {
    this.#price = account.mojolock_unit_price / 100;
  }

  getPrice() {
    return this.#price;
  }

  printFormattedPrice() {
    $('strong#fixed_mojolock_price').text('$' + this.#price);
  }

  toggleCodeboxNote() {
    DeviceUtils.hideCodeboxNumberZone();
  }

  toggleAddressBehavior() {
    DeviceUtils.showCanadian();
  }
}

export default Mojolock;