import DeviceUtils from '../device_utils';

class Vault {
  #price;

  constructor(account) {
    this.#price = account.codebox_unit_price / 100 + Number(DeviceUtils.getOrderPageData().juniorPriceDown);
  }

  getPrice() {
    return this.#price;
  }

  printFormattedPrice() {}

  toggleCodeboxNote() {
    DeviceUtils.hideCodeboxNumberZone();
  }

  toggleAddressBehavior() {
    DeviceUtils.showCanadian();
  }
}

export default Vault;