class FloorPlanForm {
  constructor(wrapper) {
    this.wrapper = wrapper;
  }

  bindEvents() {
    document.querySelector('#edit-essentials').addEventListener('click', function(){
      document.querySelector('#essentials_form').dispatchEvent(new CustomEvent('partial-form:enable'));
    });

    document.getElementById('editable-list-items').addEventListener('click', event => {
      if (event.target.className.includes('js-remove-btn')) {
        let deleteListingId = event.target.parentElement.children[0].value;
        let deleteListingTitle = event.target.parentElement.children[1].innerHTML;

        let opt = document.createElement('option');
        opt.value = deleteListingId;
        opt.innerHTML = deleteListingTitle;
        document.getElementById('listings-dropdown').appendChild(opt);

        event.target.parentElement.remove();
      }
    });

    document.getElementById('add_new_listing').addEventListener('click', (e) => {
      e.preventDefault();

      var selectedListingId = document.getElementById('listings-dropdown').value;

      if (selectedListingId) {
        var selectedListingTitle = document.getElementById('listings-dropdown').selectedOptions[0].text;

        let ele = document.getElementById('list-item-template').cloneNode(true);
        ele.children[0].value = selectedListingId;
        ele.children[1].innerHTML = selectedListingTitle;

        var listingList = document.getElementById('editable-list-items');
        listingList.appendChild(ele);

        document.getElementById('listings-dropdown').selectedOptions[0].remove();
      }

      return false;
    });
  }

  initForm() {
    this.form = this.wrapper.querySelector('form');

    if (window.location.href.includes('new')) {
      this.form.dispatchEvent(new CustomEvent('partial-form:enable'));
    }
  }

  init() {
    this.initForm();
    this.bindEvents();

    this.wrapper.addEventListener('html:updated', (e) => {
      this.initForm();
      this.bindEvents();

      if (e.detail && JSON.parse(e.detail.errors)) {
        this.form.dispatchEvent(new CustomEvent('partial-form:enable'));
      } else {
        this.form.dispatchEvent(new CustomEvent('partial-form:disabled'));
      }
    });
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const settings = document.getElementById('floor-essentials');
  if (settings) {
    const settingsInstance = new FloorPlanForm(settings);
    settingsInstance.init();
  }
});
